import React, { useState } from 'react';
import logoDurango from "../assets/DurGobEst.png"
import { useAuthenticator } from '@aws-amplify/ui-react';
import ProfilesPage from './Profiles';
import "../styles/Header.css"
import { GrUserManager } from 'react-icons/gr';
import { Button, Flex, Image, Menu, MenuItem, MenuButton, View, Icon, useBreakpointValue } from "@aws-amplify/ui-react";

import { Auth } from 'aws-amplify';
const Header = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [show, setShow] = useState(false);



  async function signOut() {
    try {
      await Auth.signOut();
      window.location.href = "/"
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  return (
    <>
      <header id="Container" style={{ position: "relative", backgroundColor: "#D4E0E0",  }}>
        <div id="page-logo">
          <img src={logoDurango} alt='LogoDurango' width={170} />
        </div>
        <nav>
          <ul>
            <li><a href="/">Inicio</a></li>
            <li><a href="/">Trámites y Servicios</a></li>
            <li><a href="/">Contacto</a></li>

            <Menu
              trigger={
                <MenuButton
                  variation="primary"
                  backgroundColor={user ? "#D4E0E0" : "#D4E0E0"}
                >
                  <Icon as={GrUserManager} />
                </MenuButton>
              }
            >
              <MenuItem style={{ borderRadius: '4px', }}
                onClick={() => setShow(true)}
              >
                Perfiles
              </MenuItem>
              <MenuItem style={{ borderRadius: '4px', }}
                onClick={() => window.location.href="/CreateForm"}
              >
                Formulario
              </MenuItem>
              <MenuItem style={{ borderRadius: '4px', }}
                onClick={() => { user ? signOut() : window.location.href = "/login" }}
              >
                Logout
              </MenuItem>
            </Menu>
            <ProfilesPage show={show} setShow={setShow} />
          </ul>
        </nav>
        <a href="#side-drawer" className="menu-btn">
          <span></span>
          <span></span>
          <span></span>
        </a>
        
      </header>
      <aside id="side-drawer">
        <header id="Container">
        <div id="page-logo">
          <img src={logoDurango} alt='LogoDurango' style={{textAlign:"left"}} width={170} />
        </div>
          <a href="#" className="menu-btn">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </header>
        <nav>
          <ul>
            <li><a href="/">Inicio</a></li>
            <li><a href="/">Trámites y Servicios</a></li>
            <li><a href="/">Contacto</a></li>
            <Button>Log</Button>
          </ul>
        </nav>
      </aside>
      
    </>
  );
}

export default Header;
