import React, { useState, useEffect } from 'react'
import { FormularioTramite } from '../components/FormularioTramite'
import { listFormularios } from '../graphql/queries';
import { API } from 'aws-amplify';
import { View } from '@aws-amplify/ui-react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap'
import * as formik from 'formik';
import * as yup from 'yup';
import { deleteFormularios, updateFormularios } from '../graphql/mutations';
export const CreateForm = () => {
  const [listForm, setListForm] = useState(undefined);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [info, setInfo] = useState({});
  const [textC, setTextC] = useState({});
  const handleClose = () => {
    setShow(false)
  };
  const handleShow = (item) => {
    setShow(true);
    setInfo(item);
    setTextC(item.campos);
  };
  const handleShow2 = () => {
    setShow2(true);

  };
  async function fetchForms() {

    const apiData = await API.graphql({
      query: listFormularios
    });
    const transFromAPI = apiData.data.listFormularios.items;
    setListForm(transFromAPI);
    console.log(transFromAPI);

  }
  useEffect(() => {
    fetchForms();
    
  }, [])
  async function deleteFormulario(id) {
    await API.graphql({
      query: deleteFormularios,
      variables: {
        input: {
          id: id
        }
      },

    });
    fetchForms();
  }

  return (
    <>
      <View>
        <div style={{ textAlign: "right", width: "90%" }}>

          <Button className='mt-3' onClick={() => handleShow2()}>Add Form</Button>
        </div>

        <FormularioTramite fetchForms={fetchForms} show2={show2} setShow2={setShow2} />
        {listForm !== undefined ?
          <ul style={{ width: "90%", textAlign: "center", margin: "auto" }}>
            {listForm.map((item) => (

              <li style={{ listStyle: "none", border: "3px solid black", borderRadius: "10px", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", display: "grid" }} className='mt-3 mb-3' key={item.id}>
                <label style={{ margin: "10px" }}>Tramite:<br />  <strong>{item.tramite}</strong> </label>
                <label style={{ margin: "10px" }}>Metodo:<br />  <strong>{item.metodo}</strong></label>
                <label style={{ margin: "10px" }}>Api:<br /> <strong>{item.api}</strong> </label>
                <label style={{ margin: "10px" }}>Dependencia:<br />  <strong>{item.dependencia}</strong> </label>
                <div>
                  <label >Campos: </label>
                  <textarea
                    disabled={true}
                    name="Campos"
                    rows="1"
                    value={item.campos}
                    cols="auto"
                    style={{ resize: "none", margin: "auto" }}
                  >
                  </textarea>
                </div>
                <div >
                  <Button style={{ margin: "20px" }} variant='primary' onClick={() => handleShow(item)}>Editar</Button>
                  <Button variant='danger' onClick={() => deleteFormulario(item.id)}>Eliminar</Button>
                </div>
              </li>
            ))}
          </ul>
          :
          null
        }

      </View>
      <ModalEdit handleClose={handleClose} show={show} info={info} fetchForms={fetchForms} textC={textC} setTextC={setTextC}/>
    </>
  )
}





const ModalEdit = (props) => {
  const { handleClose, show, info, fetchForms, textC, setTextC } = props;
  
  console.log(textC);
  const { Formik } = formik;
  const schema = yup.object().shape({
    UID: yup.string(),
    Dependencia: yup.string().required("Este Campo es Obligatorio"),
    Tramite: yup.string().required("Este Campo es Obligatorio"),
    Campos: yup.string()
      .test('valid-json', 'JSON inválido', (value) => {
        try {
          JSON.parse(value);
          return true;
        } catch (error) {
          return false;
        }
      })
      .required('Campo requerido'),
    Api: yup.string().required("Este Campo es Obligatorio"),
    Metodo: yup.string().required("Este Campo es Obligatorio"),
  });
  async function updateFormulario(valores) {
    await API.graphql({
      query: updateFormularios,
      variables: {
        input: {
          id: valores.UID,
          dependencia: valores.Dependencia,
          tramite: valores.Tramite,
          campos: valores.Campos,
          api: valores.Api,
          metodo: valores.Metodo
        }
      },

    });
    fetchForms();
  }

  const handleClick = (text) => {
    const position = text.length - 3;
    const addetxt = `,"button": {
    "name": "button",
    "label": "",
    "type": "field",
    "renderer": "button",
    "htmlClass":"d-grid gap-2 mb-3",
    "fieldClass": "btn-primary",
    "content": "",
    "buttonType": ""
}`;
    const part1 = text.slice(0, position);
    const part2 = text.slice(position);
    const result = part1 + addetxt + part2;
    setTextC(result);
    console.log(result);

  }
  const AgregarCampo = (text) => {
    const position = text.length - 3;
    const addetxt = `, "Name": {
            "name": "Name",
            "htmlClass":"mb-3 text-start fs-4 position-relative",
            "labelClass":"text-decoration-underline",
            "label": "name",
            "type": "field",
            "renderer": "text",
            "fieldType": "text",
            "errorAs":"div",
            "errorClass":"invalid-tooltip",
            "validation": [
                ["string"],
                ["required", "Este campo es requerido"]
              ]
        } `;
    const part1 = text.slice(0, position);
    const part2 = text.slice(position);
    const result = part1 + addetxt + part2;
    setTextC(result);

  }
  const AgregarSelect = (text) => {
    const position = text.length - 3;
    const addetxt = `, "Name": {"type": "field",
"renderer": "react-select",
"name": "name",
"label": "label",
"isCreatable": true,
"options": [
    {
        "value": "",
        "label": ""
    },
    {
        "value": "",
        "label": ""
    },
    {
        "value": "",
        "label": ""
    }
] }`;
    const part1 = text.slice(0, position);
    const part2 = text.slice(position);
    const result = part1 + addetxt + part2;
    setTextC(result);

  }

  return (
    <Modal show={show} size='xl'>
      <Modal.Header >
        <Modal.Title>Edita Formulario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={(valores, { resetForm }) => {
            handleClose();
            updateFormulario(valores);
          }

          }
          enableReinitialize={true}
          initialValues={{
            UID: info.id,
            Dependencia: info.dependencia,
            Tramite: info.tramite,
            Campos: textC,
            Api: info.api,
            Metodo: info.metodo,
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="FormTramite">
              <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label >
                    UID
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="UID"
                    value={values.UID}
                    onChange={handleChange}
                    isValid={touched.UID && !errors.UID}
                    isInvalid={!!errors.UID && touched.UID}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.UID}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label >
                    Dependencia
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Dependencia"
                    value={values.Dependencia}
                    onChange={handleChange}
                    isValid={touched.Dependencia && !errors.Dependencia}
                    isInvalid={!!errors.Dependencia && touched.Dependencia}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.Dependencia}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label >
                    Tramite
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Tramite"
                    value={values.Tramite}
                    onChange={handleChange}
                    isValid={touched.Tramite && !errors.Tramite}
                    isInvalid={!!errors.Tramite && touched.Tramite}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.Tramite}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">

                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label >
                    Api
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Api"
                    value={values.Api}
                    onChange={handleChange}
                    isValid={touched.Api && !errors.Api}
                    isInvalid={!!errors.Api && touched.Api}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.Api}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label>
                    Metodo
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Metodo"
                    value={values.Metodo}
                    onChange={handleChange}
                    isValid={touched.Metodo && !errors.Metodo}
                    isInvalid={!!errors.Metodo && touched.Metodo}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.Metodo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='mb-3'>

                <Form.Group as={Col} className='d-grid gap-2'>

                  <Button onClick={() => handleClick(textC)} variant='outline-primary'>Agregar boton</Button>

                </Form.Group>
                <Form.Group as={Col} className='d-grid gap-2'>

                  <Button onClick={() => AgregarCampo(textC)} variant='outline-primary'>Agregar Campo</Button>

                </Form.Group>
                <Form.Group as={Col} className='d-grid gap-2'>

                  <Button onClick={() => AgregarSelect(textC)} variant='outline-primary'>Agregar Select</Button>

                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label >
                    Campos
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={10}
                    style={{ resize: "none" }}
                    name="Campos"
                    value={values.Campos}
                    onChange={handleChange}
                    isValid={touched.Campos && !errors.Campos}
                    isInvalid={!!errors.Campos && touched.Campos}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.Campos}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="d-grid gap-2">
                  <Button type="submit" variation="primary">
                    Enviar
                  </Button>
                  <Button onClick={() => handleClose()} variant='danger'>Cancelar</Button>
                </Form.Group>

              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>

    </Modal>
  )
}