import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Authenticator, Heading, Image, View, useAuthenticator, useTheme, translations } from '@aws-amplify/ui-react';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './UserContext';
import { Amplify, I18n } from 'aws-amplify';
import config from './aws-exports';
import { Button } from 'react-bootstrap';
Amplify.configure(config);

I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabularies({
  fr: {
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire",
  },
  es: {
    'Sign In': 'Iniciar Sesion',
    'Sign Up': 'Regístrate',
  },
});


const formFields = {
  signIn: {
    username: {
      label:'Numero de Celular',
      dialCode: '+52',
      placeholder: '618*******',
    },
  },
  signUp: {
    phone_number: {
      label:'Numero de Celular',
      dialCode: '+52',
      placeholder: '618*******',
    },
  },
}

const components = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          PDP Durango
        </Heading>
      );
    },
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Authenticator formFields={formFields} components={components} >
      <UserProvider>
        <App />
      </UserProvider>
    </Authenticator>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
