import React from 'react';
import logoDurango from "../assets/DurGobEst.png"
import instagram from "../assets/instagram.png"
import facebook from "../assets/facebook.png"
import '../styles/Footer.css'
const Footer = () => {
    return (
        <footer className="footer" >
            <div className="footer-content" >
                <div className="footer-left">
                    <img src={logoDurango} alt="logo-Durango" className="footer-logo" />
                </div>
                <div className="footer-right">
                    <a><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.312 5.01699H6.645C4.355 5.01699 2.5 6.85199 2.5 9.11599V14.884C2.5 17.148 4.356 18.984 6.645 18.984H18.312C20.602 18.984 22.457 17.148 22.457 14.884V9.11599C22.457 6.85199 20.601 5.01599 18.312 5.01599V5.01699ZM15.509 12.28L10.052 14.855C10.0187 14.871 9.98192 14.8784 9.94503 14.8763C9.90815 14.8743 9.87237 14.863 9.84103 14.8434C9.8097 14.8239 9.78382 14.7967 9.7658 14.7645C9.74779 14.7322 9.73822 14.6959 9.738 14.659V9.34999C9.73867 9.31286 9.74872 9.27651 9.76722 9.24432C9.78573 9.21212 9.81208 9.18513 9.84382 9.16587C9.87556 9.1466 9.91167 9.13568 9.94877 9.13413C9.98587 9.13258 10.0228 9.14044 10.056 9.15699L15.514 11.892C15.5504 11.9101 15.5809 11.9381 15.602 11.9728C15.6232 12.0075 15.6341 12.0474 15.6336 12.0881C15.6331 12.1287 15.6211 12.1683 15.5991 12.2025C15.577 12.2366 15.5458 12.2638 15.509 12.281V12.28Z" fill="#475569" />
                    </svg>
                    </a>
                    <a href="#" className="social-icon"><img src={facebook} alt="Durango" /></a>
                    <a><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.5 5.90704C21.7504 6.23442 20.9565 6.44908 20.144 6.54404C20.9968 6.04327 21.638 5.24915 21.948 4.31004C21.14 4.78037 20.2587 5.11164 19.341 5.29004C18.9545 4.88525 18.4897 4.56343 17.9748 4.34422C17.4598 4.12501 16.9056 4.01301 16.346 4.01504C14.08 4.01504 12.243 5.82504 12.243 8.05504C12.243 8.37104 12.279 8.68004 12.349 8.97504C10.7236 8.89774 9.13212 8.48245 7.67617 7.75568C6.22022 7.02891 4.93176 6.00662 3.893 4.75404C3.52883 5.36844 3.33742 6.06982 3.339 6.78404C3.3397 7.45201 3.50683 8.10927 3.82529 8.69644C4.14375 9.2836 4.6035 9.78215 5.163 10.147C4.51248 10.126 3.87602 9.95237 3.305 9.64004V9.69004C3.305 11.648 4.72 13.281 6.595 13.653C6.24261 13.7465 5.87958 13.7939 5.515 13.794C5.25 13.794 4.993 13.769 4.742 13.719C5.01008 14.527 5.52311 15.2314 6.20982 15.7344C6.89653 16.2374 7.72284 16.5141 8.574 16.526C7.11407 17.6506 5.32182 18.2581 3.479 18.253C3.147 18.253 2.821 18.233 2.5 18.197C4.38125 19.3877 6.56259 20.0183 8.789 20.015C16.336 20.015 20.462 13.858 20.462 8.51904L20.448 7.99604C21.2529 7.42971 21.9481 6.72189 22.5 5.90704Z" fill="#475569" />
                    </svg>
                    </a>
                    <a href="#" className="social-icon"><img src={instagram} alt="Durango" /></a>
                </div>
            </div>
            <hr className="footer-divider" />
            <div className="footer-bottom">
                <span className="footer-text-left" style={{fontFamily:"roboto"}}>©2023 Gobierno del Estado de Durango</span>
                <a href="#" className="footer-text-right" style={{fontFamily:"roboto"}}>Avisos de Privacidad</a>
            </div>
        </footer>
    );
}

export default Footer;
