import { View } from '@aws-amplify/ui-react'
import React from 'react'
import { useParams } from 'react-router-dom';
import { FormActa } from '../components/Formularios/FormActa';
import FormGases from '../components/Formularios/FormGases';

export default function FormView() {
    const { id } = useParams();


    const FormSorter = () => {
        switch (Number(id)) {
            case 1:
                return <FormGases />
            case 2:
                return <FormActa />

            default:
                return <div>NO HAY FORM CON ESTE PROCESO</div>
        }
    }
    return (
        <View>
            {FormSorter()}
        </View>
    )
}
