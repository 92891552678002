import { createContext, useState, useEffect } from "react";
import {  Auth } from "aws-amplify";
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
   const getUser=async()=> {
    let userAuth = await Auth.currentAuthenticatedUser();
    setUser(userAuth.attributes);
  }
  return (
    <UserContext.Provider
      value={{ user }}
    >
      {children}
    </UserContext.Provider>
  )
}
