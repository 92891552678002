/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFormularios = /* GraphQL */ `
  mutation CreateFormularios(
    $input: CreateFormulariosInput!
    $condition: ModelFormulariosConditionInput
  ) {
    createFormularios(input: $input, condition: $condition) {
      id
      dependencia
      tramite
      api
      campos
      metodo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFormularios = /* GraphQL */ `
  mutation UpdateFormularios(
    $input: UpdateFormulariosInput!
    $condition: ModelFormulariosConditionInput
  ) {
    updateFormularios(input: $input, condition: $condition) {
      id
      dependencia
      tramite
      api
      campos
      metodo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFormularios = /* GraphQL */ `
  mutation DeleteFormularios(
    $input: DeleteFormulariosInput!
    $condition: ModelFormulariosConditionInput
  ) {
    deleteFormularios(input: $input, condition: $condition) {
      id
      dependencia
      tramite
      api
      campos
      metodo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPerfiles = /* GraphQL */ `
  mutation CreatePerfiles(
    $input: CreatePerfilesInput!
    $condition: ModelPerfilesConditionInput
  ) {
    createPerfiles(input: $input, condition: $condition) {
      id
      owner
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePerfiles = /* GraphQL */ `
  mutation UpdatePerfiles(
    $input: UpdatePerfilesInput!
    $condition: ModelPerfilesConditionInput
  ) {
    updatePerfiles(input: $input, condition: $condition) {
      id
      owner
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePerfiles = /* GraphQL */ `
  mutation DeletePerfiles(
    $input: DeletePerfilesInput!
    $condition: ModelPerfilesConditionInput
  ) {
    deletePerfiles(input: $input, condition: $condition) {
      id
      owner
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTransacciones = /* GraphQL */ `
  mutation CreateTransacciones(
    $input: CreateTransaccionesInput!
    $condition: ModelTransaccionesConditionInput
  ) {
    createTransacciones(input: $input, condition: $condition) {
      id
      owner
      tramite
      amount
      status
      paymenturl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTransacciones = /* GraphQL */ `
  mutation UpdateTransacciones(
    $input: UpdateTransaccionesInput!
    $condition: ModelTransaccionesConditionInput
  ) {
    updateTransacciones(input: $input, condition: $condition) {
      id
      owner
      tramite
      amount
      status
      paymenturl
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTransacciones = /* GraphQL */ `
  mutation DeleteTransacciones(
    $input: DeleteTransaccionesInput!
    $condition: ModelTransaccionesConditionInput
  ) {
    deleteTransacciones(input: $input, condition: $condition) {
      id
      owner
      tramite
      amount
      status
      paymenturl
      createdAt
      updatedAt
      __typename
    }
  }
`;
