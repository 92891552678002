import React, { useState, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { API, Auth } from "aws-amplify";
import {
    Button,
    Flex,
    Heading,
    Text,
    Loader,
    TextField,
    View,
} from "@aws-amplify/ui-react";

import { listTransacciones } from "../graphql/queries";
import {
    createTransacciones,
    updateTransacciones
} from "../graphql/mutations";
import Modal from 'react-modal';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
Modal.setAppElement('#root');


const TransPage = () => {
    const [trans, setTrans] = useState([]);
    const [toPay, setToPay] = useState([])
    const [payUrl, setPayUrl] = useState('');



    useEffect(() => {
        fetchTrans();
    }, [])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [transactionData, setTransactionData] = useState({
        amount: 0,
        description: ''
    });

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTransactionData({
            amount: '',
            description: ''
        });
    };

    const payTransaction = async (tranData) => {
        setIsLoading(true);
        const paymentProcess = await callLambdaFunction();
        console.log(paymentProcess);
        console.log("HERE1");
        const data = {
            tramite: 'Tramite 1--',
            amount: 0.0,
            status: 'Procesando',
            paymenturl: 'Not Set'//paymentSam.data.url,
        };
        const user = await Auth.currentAuthenticatedUser();
        data.owner = user.username; // Set the owner's ID
        console.log("HERE2");
        const sendDataPayment = {

            gases: [
                {

                    clave: tranData.TipoDeGas,

                    cantidad: Number(tranData.CantidadGas),

                }
            ],
            "transaccion": {

                "owner": user.username,

                "tramite": "Tramite - 1"

            }

        };
        const paymentSam = await paymentCall(sendDataPayment);
        console.log("DTAA SAM", paymentSam);

        console.log("HERE3");
        window.open(paymentSam.data.url)
        fetchTrans();
        closeModal();
        setIsLoading(false);
    };



    async function fetchTrans() {
        console.log("HERE1-");
        let user = await Auth.currentAuthenticatedUser();
        console.log("USER DATA", user.username);
        const filter = {
            owner: {
                eq: user.username
            }
        };
        const apiData = await API.graphql({
            query: listTransacciones,
            variables: {
                filter: filter
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS', // Adjust auth mode based on your setup
            headers: {
                Authorization: user.signInUserSession.idToken.jwtToken
            }
        });
        console.log("APIDATA", apiData);
        const transFromAPI = apiData.data.listTransacciones.items;
        setTrans(transFromAPI);
    }


    async function callLambdaFunction() {
        try {
            const response = await API.post('apiActions', '/api', {
                body: { /* data */ },
            });
            console.log('Lambda response:', response);
            return response
        } catch (error) {
            console.error('Lambda error:', error);
            return error
        }
    }

    async function paymentCall(data) {
        const urlPayment = 'https://5r0aarz3je.execute-api.us-west-2.amazonaws.com/Prod/servicios/stripe/create-checkout-session';

        try {
            const response = await axios.post(urlPayment,
                data);
            console.log("Response", response);
            return response
        } catch (error) {
            console.log("Response", error);
            return error
        }
    }

    const openPayment = (url) => {
        window.open(url)
    }


  /*   const validarRFC = () => {
        console.log("RFC");
        axios.get(`https://test-api-durango-digital.durango.gob.mx/v1/padrones/gases/${RFC}`)
            .then(response => {
                console.log('Respuesta del servidor:', response.data);
            })
            .catch(error => {
                console.error('Error al hacer la petición:', error);
            });
    } */

    return (
        <Flex direction='column'>
            <View>
                {/*                 <Heading>Trámites</Heading>
 */}                {/* <View margin="1rem 0">
                    {trans.map((tran) => (
                        <Flex
                            key={tran.id || tran.name}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Text marginRight={50} as="strong" fontWeight={700}>
                                {tran.tramite}
                            </Text>
                            <Text marginLeft={50} as="strong" fontWeight={700} textAlign={'left'}>
                                {tran.amount}
                            </Text>
                            <Text as="strong" fontWeight={700}>
                                {tran.status}
                            </Text>
                            <Text onClick={() => openPayment(tran.paymenturl)} as="strong" fontWeight={700}>
                                {tran.status === 'Procesando' ? 'Link de pago' : 'Link de recibo'}
                            </Text>
                            <Text as="strong" fontWeight={700}>
                                Detalles
                            </Text>
                        </Flex>
                    ))}
                </View> */}
                {/* <Button variation="primary" style={{width:"15%"}} isFullWidth={true} onClick={openModal}>
                    Pagar Tu Trámite
                </Button> */}
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                </Modal>
            </View >

        </Flex >
    )
}


const modalInfo = (data) => {
    const { isModalOpen, closeModal, info } = data;
    <Modal isOpen={isModalOpen} onClose={closeModal}>
        <View padding="2rem">
            <Heading level={3}>Pagar Trámite</Heading>
            <form>
                <TextField
                    name="amount"
                    label="Amount"
                    placeholder="Enter amount"
                    type="number"
                    required
                />
                <Text>Descripcion: Pulsa el boton para generar tu orden de pago.</Text>
                <Button width={'50%'} type="button" variation="primary">
                </Button>
                <Button type="button" width={'50%'} onClick={closeModal} variation="secondary">
                    Cancel
                </Button>
            </form>
        </View>
    </Modal>
}




export default TransPage;