import { Navigate, createBrowserRouter } from "react-router-dom";
import UserLayout from "./components/UserLayout";
import FormView from "./views/FormView";
import { Heading, View } from "@aws-amplify/ui-react";
import Header from "./components/Header";
import TransPage from "./components/Transactions";
import Footer from "./components/Footer";
import { CreateForm } from "./views/CreateForm";
import DynamicFormViewer from "./views/DynamicFormViewer";

const router = createBrowserRouter([
    {
        path: "/",
        element: <UserLayout />,
        children: [
            {
                path: "/",
                element:<TransPage />
            },
            {
                path: "/:id",
                element: <FormView />
            },
            {
                path: "/CreateForm",
                element: <CreateForm />
            },
            {
                path: "/DynamicForm/:id",
                element: <DynamicFormViewer />
            }
        ]
    }
])

export default router;