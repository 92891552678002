import React, { useEffect, useState } from 'react';
import { Form } from '@flipbyte/formik-json';
import { Field } from 'formik';
import { useParams } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { listFormularios } from '../graphql/queries';
import { View } from '@aws-amplify/ui-react';

const DynamicFormViewer = () => {

    const { id } = useParams();
    const [formData, setFormData] = useState({})
    const save = (values, formikProps) => {
        console.log(values);
        alert(JSON.stringify(values));
        formikProps.setSubmitting(false);
    }
    useEffect(() => {
        getFormData();
    }, [])

    async function getFormData() {
        //get data from id
        let user = await Auth.currentAuthenticatedUser();
        console.log("USER DATA", user.username);
        const filter = {
            id: {
                eq: id
            }
        };
        const apiData = await API.graphql({
            query: listFormularios,
            variables: {
                filter: filter
            },
            headers: {
                Authorization: user.signInUserSession.idToken.jwtToken
            }
        });
        const forms = apiData.data.listFormularios.items;
        console.log("FORMS data", forms);
        forms[0].campos = JSON.parse(forms[0].campos)
        console.log("FORMS data", forms);
        setFormData(forms[0])

    }
    /*
    tipogas: {
                type: "field",
                renderer: "react-select",
                name: "tipogas",
                label: "Tipo Gas",
                isCreatable: true,
                options: [{
                    value: "BC",
                    label: "Dioxido de Carbono"
                },{
                    value: "MET",
                    label: "Metano"
                },{
                    value: "OXI",
                    label: "Oxido Nitroso"
                }],
                formGroupClass: "form-group mb-4"
            },
            reactSelectMulti: {
                type: "field",
                renderer: "react-select",
                name: "react-select-multi",
                label: "React Select Multi",
                isMulti: true,
                isCreatable: true,
                options: [{
                    value: 'chocolate',
                    label: 'Chocolate'
                }, {
                    value: 'strawberry',
                    label: 'Strawberry'
                }, {
                    value: 'vanilla',
                    label: 'Vanilla'
                }],
                formGroupClass: "form-group mb-4",
                validation: [['array'], ['of', [['string']]]]
            },
    */


    console.log(formData)
    return (
        <View className='FormTramite'>
            {formData.campos ? <View className='FormTitle'>{formData?.campos?.schema?.label}</View> : null}
            {formData.campos ?
                <View className='FormContent'>
                    <Form
                        
                        schema={formData.campos.schema}
                        onSubmit={save}
                    >

                    </Form>
                </View>
                : null}

        </View>
    );
};

export default DynamicFormViewer;
