import React, { useState, useEffect,useContext } from "react";
import "./App.css";
import Header from "./components/Header";
import "@aws-amplify/ui-react/styles.css";
import {UserContext} from './UserContext';
import { API, Auth } from "aws-amplify";
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import TransPage from "./components/Transactions";
import Footer from "./components/Footer";
import { RouterProvider } from "react-router-dom";
import router from "./router";

const App = ({ signOut }) => {
  const [userGlob, setUserGlob] = useState(null);
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    setData()
  }, [])
  const setData = async() =>{
    setUserGlob(await Auth.currentAuthenticatedUser())
  }

  return(
    <View>
      <RouterProvider router={router} />
    </View>    
  )
};

export default withAuthenticator(App);